import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout";
import {Link, graphql} from "gatsby";
import Seo from "../components/seo";
import Fullactivity from "../blocks/fullactivity";
import EnterpriseActivity from "../blocks/enterpriseactivity";
import Largepopup from "../blocks/largepopup";

export const image = graphql`query HandbookTemplateQuery($ageGroup: String!, $ageGroupBadge: String!, $ageLang: String!, $handbookDownload: String!) {
  badge: file(relativePath: {eq: $ageGroupBadge}) {
    publicURL
  }
  guide: file(relativePath: {eq: $handbookDownload}) {
    publicURL
  }
  activities: allLessonsJson(filter: {ageGroup: {eq: $ageGroup}, resourceType: {ne: "enterprise"}, lang: {eq: $ageLang}}) {
    edges {
      node {
        id
        icon
        name
        url
        ageGroup
        resourcesURL
        activityNumber
        activityPlan
        description
        lang
        level
        resourceType
        short
        space
        time
      }
    }
  }
  enterprise: lessonsJson(ageGroup: {eq: $ageGroup}, resourceType: {eq: "enterprise"}, lang: {eq: $ageLang}) {
    id
    icon
    name
    url
    ageGroup
    resourcesURL
    activityNumber
    activityPlan
    description
    lang
    level
    resourceType
    short
    space
    time
  }
}`

let englishArray = {
    'banner' : {
        'para1': 'You can view the sessions on this page in the order they appear in the Teacher’s guide (as a connected journey), or you can view them as individual sessions.',
        'para2': 'Click “View as sessions” to view the sessions by duration.',
        'buttonTop': 'View as sessions',
        'buttonBtm': 'Download Teacher\'s guide'
    }
};

let welshArray = {
    'banner' : {
        'para1': 'Gallwch weld y sesiynau ar y dudalen hon yn y drefn maen nhw’n ymddangos yn y Canllaw i athrawon (fel taith gysylltiedig), neu gallwch eu gweld fel sesiynau unigol.',
        'para2': 'Cliciwch “Gweld fel sesiynau” i weld y sesiynau yn ôl hyd.',
        'buttonTop': 'Gweld fel sesiynau',
        'buttonBtm': 'Lawrlwytho’r Canllaw i athrawon'
    }
};

const HandbookTemplate = ({ data, pageContext }) => {
    let langArray = englishArray;
    let resourcesCTAWelsh = "";
    let resourcesBannerWelsh = "";
    if (pageContext.handbook.lang === 'cy') {
        langArray = welshArray;
        resourcesCTAWelsh = " resources-cta--button__cy";
        resourcesBannerWelsh = " resources-cta--banner__cy";
    }
    return (
        <Layout lang={pageContext.handbook.transitionLang} langURL={pageContext.handbook.transitionURL}>
            <Seo title={'Handbook'} lang={'en-GB'}
                 description={'You can download individual activity plans here, or view the handbook (link) to see the activities in suggested order of completion.'}/>
            <section className={'resources-banner resources-banner-' + pageContext.handbook.ageGroup} role={'banner'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row className={'d-flex'}>
                        <Col xs={12} className={'resources-banner--icon'}>
                            <img src={data.badge.publicURL} alt={'Age Badge'} className={'my-5'}/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={'resources-cta mt-5 pt-4'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} md={10} className={'offset-md-1'}>
                            <div className={'resources-cta--banner' + resourcesBannerWelsh + ' p-4 p-xl-5'}>
                                <Row>
                                    <Col xs={12} lg={7} className={'py-lg-3 mb-4 mb-lg-0 resources-cta--banner__text'}>
                                        <p><strong>{langArray.banner.para1}</strong></p>
                                        <p className={'mb-0'}>{langArray.banner.para2}</p>
                                    </Col>
                                    <Col xs={12} lg={5} className={'my-auto'}>
                                        <p className="mt-0 mb-3">
                                            <Link to={pageContext.handbook.handbookLink}
                                                  className={'resources-cta--button'}>
                                                {langArray.banner.buttonTop}
                                            </Link>
                                        </p>
                                        <p className="my-0">
                                            <a href={data.guide.publicURL} className={'resources-cta--button' + resourcesCTAWelsh} target={'_blank'} rel={'noreferrer'}>
                                                {langArray.banner.buttonBtm}
                                            </a>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={'activities pb-5'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} md={10} className={'offset-md-1'}>
                            <Row>
                                {data.activities.edges.filter(edge => edge.node.ageGroup === pageContext.handbook.ageGroup).map((val, idx) => (
                                    <Fullactivity
                                        key={idx}
                                        ageGroup={val.node.ageGroup}
                                        ageIcon={val.node.icon}
                                        activityType={val.node.resourceType}
                                        activityNumber={val.node.activityNumber}
                                        activityTitle={val.node.name}
                                        activityDescription={val.node.short}
                                        activityURL={val.node.url}
                                        activityTime={val.node.time}
                                        activityResources={val.node.level}
                                        activitySpace={val.node.space}
                                        activityLanguage={pageContext.handbook.lang}
                                    />
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <EnterpriseActivity
                ageGroup={data.enterprise.ageGroup}
                ageIcon={data.enterprise.icon}
                activityType={data.enterprise.resourceType}
                activityNumber={data.enterprise.activityNumber}
                activityTitle={data.enterprise.name}
                activityDescription={data.enterprise.short}
                activityURL={data.enterprise.url}
                activityTime={data.enterprise.time}
                activityResources={data.enterprise.level}
                activitySpace={data.enterprise.space}
                activityLanguage={pageContext.handbook.lang}
            />
            <Largepopup lang={pageContext.handbook.lang}/>
        </Layout>
    )
}

export default HandbookTemplate