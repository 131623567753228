import React from "react";
import {Col, Row} from "react-bootstrap";

import GetTracker from "../functions/gettracker";
import FeedbackPopup from "../functions/feedbackpopup";

let englishArray = {
    'blocks': {
        'time': 'Time',
        'resources': 'Resource level',
        'space': 'Space'
    }
}

let welshArray = {
    'blocks': {
        'time': 'Amser',
        'resources': 'Lefel adnoddau',
        'space': 'Lle'
    }
}

function Fullactivity({ ageGroup, ageIcon, activityNumber, activityStatus, activityTitle, activityDescription, activityURL, activityTime, activityResources, activitySpace, activityType, activityLanguage }) {
    let buttonText = "Go to story";
    let feedbackText = "Leave feedback";
    let feedbackWelshBg = "";
    let langArray = englishArray;
    if(activityLanguage === 'cy') {
        buttonText = "Ewch i'r stori";
        feedbackText = "Rhoi adborth";
        feedbackWelshBg = " activities--feedback__cy";
        langArray = welshArray;
    }

    if(activityTitle.includes('activity') || activityTitle.includes('gweithgaredd')) {
        buttonText = "Go to activity";
        if(activityLanguage === 'cy') {
            buttonText = "Ewch i'r gweithgaredd";
        }
    }

    let activityProgress = "";
    if(GetTracker(ageGroup, activityNumber)) {
        activityProgress = (<div className={'activities--checkbox'}><div className="activities--checkbox--tick"></div></div>);
    } else {
        let title = activityTitle.replace("<br>", " ");
        activityProgress = (<button type={'button'} id={'lesson' + ageGroup + '-' + activityNumber} className={'activities--feedback' + feedbackWelshBg} data-feedback={'incomplete'} data-agegroup={ageGroup} data-number={activityNumber} data-title={title} onClick={() => FeedbackPopup('lesson' + ageGroup + '-' + activityNumber)}><span className={'activities--feedback--hidden'}>{ feedbackText }</span></button>);
    }

    return (
        <Col xs={12} lg={6} className={'activities--full'}>
            <div className={'activities--background'}>
                <Row>
                    <Col xs={4}>
                        <div className={'activities--progress'}>
                            { activityProgress }
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className={'activities--badge'}>
                            <img src={'/assets/site/SVG/' + ageIcon + '.svg'} alt={'Lesson Badge'} className={'no-ie'} />
                            <img src={'/assets/site/3x/' + ageIcon + '@3x.png'} alt={'Lesson Badge'} className={'ie-fix'} />
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className={'activities--' + ageGroup + '--number'}>
                            <span>{ activityNumber }</span>
                        </div>
                    </Col>
                </Row>
                <div className={'activities--header'}>
                    <h3 className={'font-mandolin'} dangerouslySetInnerHTML={{__html: activityTitle }} />
                </div>
                <div className={'activities--body'}>
                    <div className={'activities--body--text'} dangerouslySetInnerHTML={{__html: activityDescription }} />
                    <Row>
                        <Col xs={6} sm={4} className={'activities--body--column activities--body--time order-2 order-sm-1'}>
                            <h4>{langArray.blocks.time}</h4>
                            <p dangerouslySetInnerHTML={{__html: activityTime}} />
                        </Col>
                        <Col xs={12} sm={4} className={'activities--body--column activities--body--resources order-1 order-sm-2'}>
                            <h4>{langArray.blocks.resources}</h4>
                            <p dangerouslySetInnerHTML={{__html: activityResources }} />
                        </Col>
                        <Col xs={6} sm={4} className={'activities--body--column activities--body--space order-3 pr-lg-4'}>
                            <h4>{langArray.blocks.space}</h4>
                            <p dangerouslySetInnerHTML={{__html: activitySpace }} />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={'activities--button'}>
                <a href={activityURL}>{buttonText}</a>
            </div>
        </Col>
    );
}

export default Fullactivity